/*=============================================================================================	
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Desi Karunia Sari
==============================================================================================*/
@import "sass-lib.scss";
@media (min-width: 0px) and (max-width:1204px){
	.wrapper-large, .wrapper-medium, .wrapper-small{width: 100vw;margin: 0 auto; max-width: 100%;}
    header{
    	.wrapper-large{
    		ul{
	    		&.menu{
	    			/*margin-right: 220px;*/
	    			li{
	    				a{
	    					padding: 10px 10px;
	    				}
                        &.search-btn{
                            input{
                                outline: none;
                                &[type=search] {
                                    background: url(../images/material/search.png) no-repeat 4px center;border:none;padding: 12px 10px 10px 32px;width: 20px;border-radius: 5px;-webkit-transition: all .5s;-moz-transition: all .5s;transition: all .5s;cursor: pointer;position: absolute;right: 10px;position: relative;font-size: 16px;
                                    &:focus{
                                        width: 600px;background: #fff url(../images/material/search-active.png) no-repeat 4px center;border-color: #333;-webkit-box-shadow: 0 0 5px rgba(109,207,246,.5);-moz-box-shadow: 0 0 5px rgba(109,207,246,.5);box-shadow: 0 0 5px rgba(109,207,246,.5);right: 580px;
                                    }
                                }
                                &:-moz-placeholder{color: transparent;}
                            }
                        }
	    			}
	    		}
    		}
    	}
    	&.active{
    		.wrapper-large{
    			ul{
    				&.menu{
    					li{
    						a{
    							padding: 10px 10px;
    						}
                            &.search-btn{
                                input{
                                    outline: none;
                                    &[type=search] {
                                        background: url(../images/material/search.png) no-repeat 4px center;border:none;padding: 12px 10px 10px 32px;width: 20px;border-radius: 5px;-webkit-transition: all .5s;-moz-transition: all .5s;transition: all .5s;cursor: pointer;position: absolute;right: 10px;position: relative;font-size: 16px;
                                        &:focus{
                                            width: 600px;background: #fff url(../images/material/search-active.png) no-repeat 4px center;border-color: #333;-webkit-box-shadow: 0 0 5px rgba(109,207,246,.5);-moz-box-shadow: 0 0 5px rgba(109,207,246,.5);box-shadow: 0 0 5px rgba(109,207,246,.5);right: 580px;
                                        }
                                    }
                                    &:-moz-placeholder{color: transparent;}
                                }
                            }
    					}
    				}
    			}
    		}
    	}
    }
    .landing{
    	.highlight-text{
    		/*left: 20%; top: 40%;*/
    	}
    	.box{
    		.index-img{
    			.desc-landing{
    				width: 28.4vw;
    			}
    		}
    	}
    }
    .homepage{
        .main-slider{
            .list{
                height: auto;
                .slider-img{
                    height: auto; width: 100%;
                }
                &.slick-active img{ animation: unset; }
            }
            .slick-dots{
                li{
                    button{
                        left: 850px;
                    }
                }
            }
            .content{ 
                width: 70%;
                h1{ line-height: 55px; } 
            }
        }
    }
    .latest-news{
    	.box-latest-news{
    		.box-news{
    			padding: 30px;width: 33.3%; margin-top: 0; margin-bottom: 20px;
    		}
            .box-news-title{ width: 100%; padding: 50px 0px 0px 30px; min-height: 150px;}
    	}
    }
    .about-us{
        border-left: 0; position: relative;
        &:before{content: ""; position: absolute; top: 0; left: 0; bottom: 0; background: #0083c3; width: 170px; z-index: -1;}
    	.content{
    		.img-content{
    			margin-left: 0%;
    			img{
    				width: 455px;
    			}
    		}
    		.desc-content{
    			width: 50%; padding-bottom: 0px; margin: 0; float: right;
    		}
    	}
    }
    .static-pages{
    	.main-banner{
            height: auto
    	}
    }
    .search-result{ width: auto; padding: 0 30px; }
    .mid-content{
    	padding: 100px 30px; @include boxSizing(border-box);
        &.detail-profile{
            .middle{
                .content-left{ width:50%; display: none; }
                .content-right{ width: 100%; }
            }
        }
    	.middle{
    		.box-content{
    			width: 28%;
    		}
    		.news{
    			.news-list{
    				/*width: 70%;*/
    			}
    		}
    		.list-profile{
    			.profile{
    				width: 310px; margin-left: 5px;
                    .images {
                        width: 266px; display: inline;
                        img{ width: 266px; max-height: 150px;}
                    }
    			}
    		}
            .box-project{
                .image-project{
                    .content{
                        padding: 0; bottom: 20px; left: 15px; width: auto;
                        p{ margin:0; font-size: 16px; }
                        h3{font-size: 32px;}
                    }
                    &:hover img{
                        filter: brightness(50%);
                    }
                    &:hover .content{ background: none; }
                }
            }
    	}
    	&.news-list{
    		padding: 0 30px;@include boxSizing(border-box);
    	}
    	&.visionmission{
    		padding: 60px 30px;
    		.content-left{
    			width: 40%;
    		}
    		.content-right{
    			width: 60%;margin-left: -50px;
    			.content, .title{
    				width: 65%;
    			}
    		}
    	}
    	&.history{
    		padding: 100px 0;
    		.content-left{
    			padding: 0 30px;width: 50%;@include boxSizing(border-box);
    			.image-cover{
    				img{
    					width: 100%;
    				}
    			}
    		}
    		.content-right{
    			width: 50%; padding-right: 30px; @include boxSizing(border-box);
    			.description, .date-title, h5{
    				/*width: 60%;*/
    			}
    		}
    		.history-nav{
    			width: 100vw;
    			.item{
    				/*padding: 0 50px;*/
    				&:after{
    					/*margin-left:30px;*/
    				}
    				p{
    					text-align: center;
    				}
    			}
    		}
    	}
    	&#management{
    		.wrapper-medium{
    			width: 1050px;
    			.highlight-text{
    				font-size: 31px;
    			}
    			.content-left{
    				.headline-text{
    					font-size: 33px;
    				}
    			}
    		}
    	}
    	&.detail-profile{
	    	.wrapper-medium{
	    		width: 900px;
	    		.content-left{
	    			width: 40%;
	    			.image-cover{
	    				img{
	    					width: 35vw;
	    				}
	    			}
	    		}
	    	}
    	}
    	&.box-project-sec{
    		width: 100vw;padding: 100px 0; 
    		.middle{
    			.box-project{
    				.image-project{
    					width: 46%;
    				}
    			}
    		}
    	}
    	&.news-sec{
    		.wrapper-medium{
    			.wrapper-medium{
    				width: 100vw;margin: 0;
    				.news-slide{
    					width: auto;
    					.news{
    						width:22.3vw; margin-right: 8px; 
    					}
    					.slick-list{
    						width: 94vw;
    					}
    					.nav-right{
    						right: 100px;
    					}
    					.nav-left{
    						right: 250px;
    					}
    				}
    			}
    		}
    	}
    }
    .pagingInfo{
    	right: 125px;
    }
	.box-profile{
		width: 100vw;
	}
	&.image-grid{
		width: 967px;
	}
    .box-video{
    	width: 100vw;left: 0px;
    	.video-project{
    		height: 700px;
    		.item{
    			width: 100vw;height: 560px;
    			.thumb{
    				width: 100%; height: 100%; object-fit: cover;
    			}
    			.desc-project{
    				width: 920px;left: 25px;padding: 30px;bottom: -15px;
    				.description{
    					margin-top: 0px;
    				}
    				.info-text{
    					margin-top: 20px;
    				}
    			}
    		}
    		.video-slider{
    			.item{
    				.cover-thumb{
                        top: 26%; left: 0; right: 0; text-align: center;
    					p{
    						font-size: 23px;
    					}
                        img{ margin-bottom: 15px; }
    				}
    			}
                iframe{ width: 100%; height: 575px; }
    		}
    	}
    	.slick-arrow{
    		&.nav-left{
    			bottom: 225px;right: 150px;
    		}
    		&.nav-right{
    			bottom: 225px;right: 80px;
    		}
    	}
    }
	.menu-id{
		padding: 40px 30px;
	}
	.csr-slider{
		.slick-dots{
			li{
				button{
					bottom: 30px;left: 850px;
				}
			}
		}
	}
	.box-contact{
		.wrapper-medium{
			width: 100vw; padding: 0px 30px;@include boxSizing(border-box);
			.form{
				.field{
					input[type="text"],input[type="email"],textarea{
						width: 72vw;
					}
					input[type="submit"]{
						padding: 15px 5px;
						width: 150px;
					}
				}
			}
		}
	}
	.overlay{
		position: fixed;background: rgba(0, 0, 0, 0.6);top: 0;bottom: 0;left: 0;right: 0;margin: auto;z-index: 3;opacity: 0;display: none;
	}
    .homepage-project{
        p{font-weight: lighter;color: #999;}
        a{text-align: right;}
        .box-40{width: 40% !important}
        .box-20{width: 20% !important}
        .middle {
            .box-content{
                width: 100%;
                h3{  }
                p{ padding: 0; }
                .text-direct{ margin: 0; }
            } 
        }
    }
    .static-page{ overflow: hidden; }
    .profile-slider{
        width: 100%;margin-left: 10px;
        .nav-right{
            position: absolute;right: 0px;top: 80px;
        }
        .nav-left{
            position: absolute;left: -20px;top: 80px;
        }
    }
    .modal .modal-content{ left: 65%; }
    .modal .close{ right: -30px; }
    .modal video{ height: auto; width: 550px; }
    #ytplayer{ height: 365px; width: 100%; position: relative; left: -10%; }
    .box-video.details .video-project .vid_web iframe{ width: 100%; height: 576px; }
    footer{
    	.info{
    		margin-left: 0;
    		.address{
    			margin-left: 10px;
    		}
    	}
    	.menu-footer{
    		ul{
    			li{
    				padding-left: 15px; 
    			}
    		}
    	}
    }
    .box-video.details{
        margin: 0; margin: auto;
        .video-project{
            &.detail-project{ margin: 0; height: auto; }
            .video-slider{
                .item{ 
                    height: auto; overflow: hidden; border-radius: 5px; 
                    .thumb{ position: absolute; top: 0; bottom:0; left: 0; right: 0; z-index: 1; object-position: center; height: 576px;}
                    .cover-thumb{ z-index: 2; top: 35%; }
                    .desc-project-detail{ bottom: 30px; display: block; z-index: 2; }

                }
            }
            .vid_web{ 
                height: 576px; 
                video{ height: 576px; }
            }
        }
    }
}

@media (min-width: 0px) and (max-width:780px){
    .wrapper-large, .wrapper-medium, .wrapper-small{width: 100vw;margin: 0 auto; max-width: 100%;}
    .cmap{ height: 375px; }
    .news-detail .middle p img{ width: 100%; height: auto; }
    header{
        /*overflow-x: hidden;*/
    	.panel{
    		width:250px;float:right;height:100vh;position:absolute;right: -500px;background: #0083c3;top: 76px;z-index: 2;padding: 30px; opacity: 0; display: none;
            .close-menu{
                position: absolute;display:  inline-block;width: 30px;height: 30px;margin: 25px;
                span{
                    margin: 0 auto; position: relative; top: -20px; left:-100px; margin-top: 0; -webkit-transform: rotate(-45deg); transform: rotate(-45deg);
                    &:before,&:after{
                        position: absolute; content: '';
                    }
                    &:before{
                        margin-top: -12px;
                    }
                    &:after{
                        margin-top: 12px; margin-top: 0; -webkit-transform: rotate(85deg); transform: rotate(85deg);
                    }
                    , &:after{
                        width: 30px; height: 3px; background-color: #fff; display: block;
                    }

                }
            }
    	}
        .wrapper-large{ padding:15px 80px 0px 20px; }
    	.burger-slide{
    		padding:15px;width:45px;float:left;color:#000;text-decoration:none;position:absolute;left: 90%;display: block; @include boxSizing(border-box);
            img{position: absolute; top: 50%; left: 50%; @include transform(translate(-50%, -50%));}
            .white{display: block;}
            .active{display: none;}
    	}
        &.act{ overflow: hidden; position: fixed; height: 100%; width: 100%; }
    	&.active{
            .panel{ top: 71px; }
    		.wrapper-large{
                padding:30px 80px 0px 20px;
	    		ul{
	    			&.menu{
                        height: 280px; overflow-y: auto; overflow-x: hidden;
	    				li{
	    					margin-bottom: 30px;width: 200px;
	    					a{
	    						font-size: 16px;color: #fff;
	    					}
	    					&.search-btn{
                                width: 100px; border-right: none; padding: 10px; padding-right: 30px; cursor: pointer;       
                                img{width: 20px;margin-top: 0px;}    
                                img.active{display: none;}
                                img.white{display: inline;}
                                 /*input{display: none}     */
                                input{
                                    outline: none;
                                    &[type=search] {
                                        width: 200px;background: url(../images/material/search-mob.png) no-repeat 4px center;border-color: #fff;right: 2px; border: none;-webkit-transition: all .5s;-moz-transition: all .5s;transition: all .5s;cursor: pointer;font-size: 16px; color: #fff; padding: 12px 10px 10px 40px;
                                        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                                            color: #fff;
                                        }
                                        &::-moz-placeholder { /* Firefox 19+ */
                                            color: #fff;
                                        }
                                        &:-ms-input-placeholder { /* IE 10+ */
                                            color: #fff;
                                        }
                                        &:-moz-placeholder { /* Firefox 18- */
                                            color: #fff;
                                        }
                                        &:focus{
                                            width: 200px;background: url(../images/material/search-mob.png) no-repeat 4px center;border-color: #fff;right: 2px; border: none; border-bottom: 1px solid #fff;
                                        }
                                    }
                                    &:-moz-placeholder{color: transparent;}
                                }
                            }
                            &.lang{
                                color: #fff;
                                a{
                                    &.active{
                                        color: #fff;
                                    }
                                }
                            }
	    				}
	    			}
	    		}
                .burger-slide{
                    padding:30px;width:45px;float:left;color:#000;text-decoration:none;position:absolute;left: 90%;display: block;
                    .white{display: none;}
                    .active{display: block;margin-top: -25px;}
                }
	    	}
    	}
    	.wrapper-large{
    		ul{
    			&.menu{
                    margin-right: 15px;height: 280px; overflow-y: auto; overflow-x: hidden;
    				li{
    					margin-bottom: 30px;width: 200px;
    					a{
    						font-size: 16px;
    					}
    					&.search-btn{
                            width: 100px; border-right: none; padding: 10px; padding-right: 30px; cursor: pointer;       
                            img{width: 20px;margin-top: 0px;}    
                            img.active{display: none;}
                            img.white{display: inline;}
                             /*input{display: none}     */
                            input{
                                outline: none;
                                &[type=search] {
                                    width: 200px;background: url(../images/material/search-mob.png) no-repeat 4px center;border-color: #fff;right: 2px; border: none;-webkit-transition: all .5s;-moz-transition: all .5s;transition: all .5s;cursor: pointer;font-size: 16px; color: #fff; padding: 12px 10px 10px 40px;
                                    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                                        color: #fff;
                                    }
                                    &::-moz-placeholder { /* Firefox 19+ */
                                        color: #fff;
                                    }
                                    &:-ms-input-placeholder { /* IE 10+ */
                                        color: #fff;
                                    }
                                    &:-moz-placeholder { /* Firefox 18- */
                                        color: #fff;
                                    }
                                    &:focus{
                                        width: 200px;background: url(../images/material/search-mob.png) no-repeat 4px center;border-color: #fff;right: 2px; border: none; border-bottom: 1px solid #fff;
                                    }
                                }
                                &:-moz-placeholder{color: transparent;}
                            }
                        }
    				}
    			}
    		}
    	}
    }
    .landing{
        .highlight-text{
            display: none;
        }
        .box{
            .index-img{
                float: none;height: 340px;
                .desc-landing{
                    width: 100vw;padding-top: 105px;position: absolute; top: 0;
                    &:hover{ background-color: transparent; }
                    & > img{ width: 50px; }
                }
                .img-landing{ 
                    width: 100vw; 
                    .bg{ height: auto; width: 100%; }
                }
            }
        }
    }
    .latest-news{
        .box-latest-news{
            .box-news-title{
                padding: 45px 0 35px 20px;min-height: unset; width: 100%;
            }
            .box-news{
                padding-top: 0;width: 33.33%;min-height: 250px; margin-top: 0;
                .box-title{
                      min-height: 60px;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 2;
                }
            }
        }
    }
    .homepage{
        .main-slider{
            .list{
                height: auto;
                .slider-img{
                    height: auto; width: 100%;
                }
                &.slick-active img{ animation: unset; }
            }
            .content{
                width: 80%;
                h1{
                    font-size: 40px;line-height: unset;
                }
                p{
                    font-size: 19px;line-height: 27px;
                }
            }
            .slick-dots li button{ left: 600px }
        }
    }
    .about-us{
        padding: 115px 20px; overflow: hidden; @include boxSizing(border-box);
        .content{
            .img-content{
                position: relative; left: auto; margin-left: 0; width: 46.15%;
                img{
                   width: 100%; 
                }
            }
            .desc-content{
                margin-left: 0;width: 53%; @include boxSizing(border-box);
                .logo-content{
                    width: 120px;
                }
                .highlight-text{
                    font-size: 23px;line-height: 30px; width: auto;
                }
                .description{
                    font-size: 16px;line-height: 24px; width: auto;
                }
            }
        }
    }
    .mid-content{
        padding: 45px 30px; @include boxSizing(border-box);
        .wrapper-small{
            width: 700px;
        }
        .middle{
            .box-content{
                h3{
                    font-size: 23px;line-height: 33px;
                }
                p{
                    font-size: 16px;line-height: 24px;
                }
            }
            .info{
                font-size: 23px;line-height: 30px;
            }
            .news{
                /*padding-top: 70px;*/
            }
            .list-profile{
                .profile{
                    width: 350px;
                    .name{
                        font-size: 19px;line-height: 20px;
                    }
                }
            }
            .highlight-text{
                font-size: 23px;line-height: 33px;
            }
            p{
                font-size: 16px;line-height: 30px;
            }
            .name-project{
                font-size: 33px;
            }
        }
        &.history{
            .content-left{
                .image-cover{
                    img{

                    }
                }
            }
            .history-nav{
                .item{
                    /*padding: 0 100px;width: 160px !important;*/
                }
                .slick-slider{
                    .slick-list{
                        width: auto;
                    }
                }
            }
        }
        &.visionmission{ 
            .content-right{
                width: 50%;
                .content{
                    width: 100%;
                }
            }
            .content-left{
                width: 50%;
            }
        }
        &#management{
            .wrapper-medium{
                width:auto;
                .content-left{
                    .headline-text{
                        font-size: 23px;line-height: 33px;
                    }
                }
                .content-right{
                    .description-text{
                        font-size: 16px; line-height: 32px;
                    }
                }
            }
        }
        &.wrapper-small{
            padding: 45px 0;
            .middle{
                width: auto; margin: 30px;
                .highlight-text{
                    font-size: 23px; line-height: 30px;
                }
                .description{
                    font-size: 16px;line-height: 27px;
                }
                .box-detail{
                    .detail-project{ width: 45.9%; }
                }
            }
        }
        &.box-project-sec{
            .middle{
                .box-project{
                    .image-project{
                        max-height: 195px;
                    }
                }
            }
        }
        &.news-sec{
            .wrapper-medium{
                .wrapper-medium{
                    .news-slide{
                        .news{
                            width: 44.7vw;padding-top: 0px;
                            .name{
                                font-size: 19px;
                            }
                        }
                    }
                }
            }
        }
    }
    .pagingInfo{
        bottom: 100px;
    }
    .image-grid{
        width: 700px;
    }
    .profile-slider{
        width: 90%;margin-left: 30px;
        .nav-right{
            position: absolute;right: -30px;top: 80px;
        }
        .nav-left{
            position: absolute;left: -30px;top: 80px;
        }
    }
    .box-video{
        margin-bottom: 50px;
        .video-project{
            .video-slider{
                iframe{ width: 100%; height: 432px; }
            }
            .item{
                .desc-project{
                    width: 85vw; left: 0;
                    .info-text{
                        width: 36vw;
                        h2{
                            font-size: 23px;
                        }
                    }
                    .numb{
                        font-size: 16px;
                    }
                }
                .desc-project-detail{
                    .numb{
                        width: 78%;
                    }
                }
            }
        }
        .slick-arrow.nav-left{ bottom: 210px; }
        .slick-arrow.nav-right{ bottom: 210px; }
    }
    .box-contact{
        .wrapper-small{
            width: 100vw; padding: 0 30px; box-sizing: border-box;
            .headline-text{
                font-size: 25px;
            }
        }
        .wrapper-medium{
            .fl{
                width: 100%;float: none;
            }
            .fr{
                float: none;
            }
            .form{
                .field{
                    &.fr{
                        margin-top: -70px;float: right;
                    }
                }
            }
            .address{
                left: 0;top: -50px;
            }
        }
    }
    .csr-slider{
        .slick-dots{
            li{
                button{
                    bottom: 30px;left: 600px;
                }
            }
        }
    } 
    .modal{
        .modal-content{ width: auto;left: 15%; margin-left: 0; top: 100px}
        .close{ top: 0; right: -320px; }
    }
    .mid-content.news-list{ margin-top: 30px; }
    .box-video .video-project .item .desc-project .description{ height: 100px; }
    .box-video .video-project .item{ height: 490px; }
    /*.video-project .video-slider .item .desc-project-detail{ bottom: 290px; }*/
    .box-video .video-project{ height: 600px; }
    /*.box-video.details .slick-arrow.nav-left{ bottom: 140px; }
    .box-video.details .slick-arrow.nav-right{ bottom: 140px; }*/
    .box-video .video-project .item .thumb{ height:88%; }
    .vid_web video{position: absolute;bottom: auto;top:0;height: 430px}
    .box-video.details{
        margin: 0; margin: auto;
        .video-project{
            &.detail-project{ margin: 0; height: auto; }
            .video-slider{
                .item{ 
                    height: auto; overflow: hidden; border-radius: 5px; 
                    .thumb{ position: absolute; top: 0; bottom:0; left: 0; right: 0; z-index: 1; object-position: center; height: 432px;}
                    .cover-thumb{ z-index: 2; top: 35%; }
                    .desc-project-detail{ bottom: 30px; display: block; z-index: 2; }

                }
            }
            .vid_web{ 
                height: 432px;
                video{ height: 432px; } 
            }
        }
    }
    #ytplayer{ height: 338px; width: 200%; position: relative; left: -10%; }
    .drive-viewer-video-preview-img{ height: auto; width: 100%; }
    .drive-viewer-video-play-button{ top: 30%; }
    .box-video.details .video-project .vid_web iframe{ width: 100%; height: 432px; }
    footer{
        height: 100px; padding: 40px 40px 20px 40px;
            &:after{ clear:both;display: block;content:" " }
        .info{
            /*display: none;*/
            .address{ display: none; }
        }
        .fr{
            float: right;
        }
        .menu-footer{
            margin-top: unset; margin-right: unset;
            ul{
                /*display: none;*/
            }
            p{
                font-size: 12px;float: none;margin-top: 20px;text-align: right;
            }
        } 
    }
}